import React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Fade from 'react-reveal/Fade';
import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import MenuGlobal from '../components/menu'
import InnerComponent from '../components/innerComponent'


import '../sass/app.scss';
import '../sass/policies.scss';

const PrivacyPage = ({ location }) => {
  const breakpoints = useBreakpoint();

  const { t } = useTranslation()
  const dataGlobal = t("global", { returnObjects: true })
  const text = {
    "title": null,
    "top": {
      "slogan": {
        "blue": ["0"],
        "first": [dataGlobal.privacy.title],
        "second": []
      },
      "sloganmobile": {
        "blue": ["0"],
        "first": [dataGlobal.privacy.title
        ],
        "second": [
        ],
        "third": [],
        "fourth": []
      }
    }
  }

  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={dataGlobal} page={dataGlobal.privacy.page} seo={dataGlobal.privacy.seo} noindex location={location} >

      {breakpoints.mobile ? <MenuGlobal video src="/assets/videos/bg_privacy_fyc8bd.mp4"
        global={dataGlobal} mobile location={location} dark textstatic={text} />
        : <MenuGlobal video src="/assets/videos/bg_privacy_fyc8bd.mp4"
          global={dataGlobal} location={location} dark textstatic={text} />
      }
      <SmoothScroll>
        <Fade bottom distance="5%" duration={1500}>
          <InnerComponent data={dataGlobal.privacy} />
        </Fade>
        
        <div className="container-scroll-heigth" />

      </SmoothScroll>
    </Layout>

  )
}

export default PrivacyPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`