
export function getPublicPath(publicsUrl, url) {
    let obj = publicsUrl.find(o => o.node.relativePath === url);
    if (obj)
        return obj.node.publicURL
}

export function getPublicPathGatsbyImage(publicsUrl, url) {
    let obj = publicsUrl.find(o => o.node.relativePath === url);
    if (obj)
        return obj.node.childImageSharp.gatsbyImageData
}

export function splitToNChunks(array, n) {
    let result = [];
    for (let i = 0; i < array.length; i += n) {
        const chunk = array.slice(i, i + n);
        result.push(chunk);
    }
    return result;
}

export function filterByKeyword(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setKeywordToSearch,
    setClientToSearch, setNotFound, keywordToSearch) {


    if (keywordToSearch && keywordToSearch !== 'all') {

        var newurl = ''
        var newProjectsFiltered = allProjects.filter((project) => {
            for (const key of project.keywords.nodes) {
                if (key.slug === keywordToSearch) {
                    return project;
                }
                //  else return null;
            }
             return null;
        })

        if (newProjectsFiltered.length > 0) {
            setNotFound(false)
            setPagination(Math.ceil(newProjectsFiltered.length / projectsPerPage))
            var paginationAux = []
            paginationAux = splitToNChunks(newProjectsFiltered, projectsPerPage)
            setProjects(paginationAux)

        }
        else if (newProjectsFiltered.length <= 0) {
            //  cleanFilter(setKeywordToSearch, setClientToSearch, allProjects, projectsPerPage, setPage, location, setProjects)
            setNotFound(true)
        }

        if (document !== `undefined`) {
            var el = document.getElementById('select-client')
            if(el)
                el.selectedIndex = 0
        }

        newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?keyword=' + keywordToSearch + '&page=1';
        window.history.pushState({ path: newurl }, '', newurl);
        location.search = '?keyword=' + keywordToSearch + '&page=1'
        setKeywordToSearch(keywordToSearch)
        setClientToSearch('')
        setPage(1)
    }
    else if (keywordToSearch && keywordToSearch === 'all') {

        paginationAux = splitToNChunks(allProjects, projectsPerPage)


        newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?keyword=' + keywordToSearch + '&page=1';
        window.history.pushState({ path: newurl }, '', newurl);
        location.search = '?keyword=' + keywordToSearch + '&page=1'
        setKeywordToSearch(keywordToSearch)
        setProjects(paginationAux)
        setPage(1)
        setClientToSearch('')
    }
    if (typeof window !== 'undefined')
        window.scrollTo(0, window.innerHeight);
}


export function filterByClient(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setClientToSearch, setKeywordToSearch, setNotFound, clientToSearch, mobile) {

    var newurl = ''
    var newProjectsFiltered = allProjects.filter((project) => {
        for (const client of project.clientes.nodes) {
            if (client.slug === clientToSearch) {
                return project;
            } 
            return null;
        }
        return null;
    })

    newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?client=' + clientToSearch + '&page=1';
    window.history.pushState({ path: newurl }, '', newurl);
    location.search = '?client=' + clientToSearch + '&page=1'

    if (newProjectsFiltered.length > 0) {
        setNotFound(false)
        setPagination(Math.ceil(newProjectsFiltered.length / projectsPerPage))
        var paginationAux = []
        paginationAux = splitToNChunks(newProjectsFiltered, projectsPerPage)
        setClientToSearch(clientToSearch)
        setKeywordToSearch('')
        setProjects(paginationAux)
        setPage(1)

    }
    else if (newProjectsFiltered.length <= 0) {
        cleanFilter(setKeywordToSearch, setClientToSearch, allProjects, projectsPerPage, setPage, location, setProjects)
        setNotFound(true)
    }

    if (document !== `undefined` && mobile) {
        document.getElementById('select-keywords').selectedIndex = 0
    }

    if (typeof window !== 'undefined')
        window.scrollTo(0, window.innerHeight);
}

export function cleanFilter(setKeywordToSearch, setClientToSearch, allProjects, projectsPerPage, setPage, location, setProjects) {
    //console.log('cleanFilter()')
    setKeywordToSearch('')
    setClientToSearch('')

    var paginationAux = []
    paginationAux = splitToNChunks(allProjects, projectsPerPage)
    setProjects(paginationAux)
    setPage(1)
    // var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
    // window.history.pushState({ path: newurl }, '', newurl);
    // location.search = ''
}